import React, { useState, Fragment, useEffect } from "react";
import DataTable from "react-data-table-component";
import CountUp from "react-countup";
import { toast } from "react-toastify";
import API from "../../services/API";
import { Search } from 'react-feather';
import DatePicker from "react-datepicker";
import { debounce } from "lodash";
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Button } from 'reactstrap'
import moment from "moment";

const Homepage = () => {
    const [state, setState] = useState({
        totalStudent: 0,
    });

    const [data, setData] = useState([])
    const [searchType, setSearchType] = useState('0')
    const [query, setQuery] = useState("")
    const [userRegistered,setUserRegistered]=useState(0);
    const [showModal, setShowModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date());
    const [format_start_date, setFormatStartDate] = useState(
        moment().format("YYYY-MM-DD")
    );
    const [format_end_date, setFormatEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [modalData, setModalData] = useState(null); // Store selected row data
    const [remarks, setRemarks] = useState("");
    const [amount, setAmount] = useState("");
    const [spending, setSpending] = useState([]);
    const [walletBalance, setWalletBalance] = useState(0);
    const [pendingTopUp, setPendingTopUp] = useState(0);
    const [withdrawTxn, setWithdrawTxn] = useState([]);

    const debouncedSearch = debounce((start, end) => {
        onSearchWithUpdatedState(start, end);
    }, 300);

    const handleStartDate = (date) => {
        const newStartDate = moment(date).format("YYYY-MM-DD");
        if (newStartDate !== format_start_date) {
            setFormatStartDate(newStartDate);
            setStartDate(date);
        }
    };

    const handleEndDate = (date) => {
        const newEndDate = moment(date).format("YYYY-MM-DD");
        if (newEndDate !== format_end_date) {
            setFormatEndDate(newEndDate);
            setEndDate(date);
        }
    };
    const FileDownload = require("js-file-download");
    const handleOpenModal = (row, newStatus) => {
        setModalData({ ...row, newStatus });
        setRemarks(row.remarks || "");
        setAmount(row.amount || "");
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalData(null);
        setRemarks("");
        setAmount("");
    };
    const onClickDownload = () => {
        var headers = {
            responseType: 'blob',
            'token': localStorage.getItem('token')
        }
        API.post("/internal/downloadTopupTxnCSV", {
            "start_date": format_start_date,
            "end_date": format_end_date,
            "search_type": searchType,
            "query": query
        }, { headers }).then(({ data }) => {
            FileDownload(data, "schoolpay_topup.csv");
        }).catch(err => {
            console.log("Error: ", err);
        });
    }
    const handleModalSubmit = async () => {
        if (!modalData) return;

        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            token: localStorage.getItem("token"),
        };

        const payload = {
            txn_id: modalData.topup_id || modalData.withdrawal_id,
            status: modalData.newStatus,
            remarks,
            amount,
        };

        const endpoint = modalData.topup_id
            ? "/topup/adminTopUpComplete"
            : "/withdraw/updateStatus";

        try {
            const response = await API.post(endpoint, payload, { headers });
            if (response.data.response_code === 1000) {
                toast.success("Transaction updated successfully!", { autoClose: 5000 });
                fetchTransactions(); // Refresh transactions
                handleCloseModal();
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to update transaction. Please try again.", {
                autoClose: 5000,
            });
        }
    };
    // Trigger search when date or search parameters change
    useEffect(() => {
        debouncedSearch(format_start_date, format_end_date);
    }, [format_start_date, format_end_date,query]);

    const onSearchWithUpdatedState = (start, end) => {
        const headers = {
            responseType: 'blob',
            'token': localStorage.getItem('token')
        };
        const payload = {
            start_date: start,
            end_date: end,
            //search_type: searchType, // 0: All, 1: Phone Number, 2: Name
            search: query,
        };
    
        API.post("/internal/topupTxn", payload, { headers })
            .then(({ data }) => {
                if (data.response_code === 1000) {
                    setData(data.data);
                } else {
                    setData([]);
                }
            })
            .catch((err) => {
                setData([]);
                console.error("Error fetching data:", err);
            });
    };
    const columns = [
        // Top-up columns
        {
            name: "ID",
            selector: (row) => row.topup_id.slice(-12),
            sortable: true,
            center: true,
        },
        {
            name: "Full Name",
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            name: "Phone No",
            selector: (row) => row.username,
            sortable: true,
            center: true,
        },
        {
            name: "Amount (RM)",
            selector: (row) => row.amount,
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            cell: (row) => {
                const statusMap = {
                    "2": { text: "Pending Verification", bgColor: "orange" },
                    "1": { text: "Success", bgColor: "green" },
                    "0": { text: "Cancelled", bgColor: "red" },
                };
                const status = statusMap[row.status] || { text: "Unknown", bgColor: "gray" };

                return (
                    <div
                        style={{
                            backgroundColor: status.bgColor,
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            textAlign: "center",
                            display: "inline-block",
                        }}
                    >
                        {status.text}
                    </div>
                );
            },
            sortable: true,
            center: true,
        },
        {
            name: "Created Time",
            selector: (row) => moment.unix(row.created_time).format("DD/MM/YYYY HH:mmA"),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    {row.status !== 1 && (
                        <select
                            className="form-control form-control-sm"
                            value=""
                            onChange={(e) => handleOpenModal(row, e.target.value)}
                        >
                            <option value="">Select Action</option>
                            <option value="1">Mark as Completed</option>
                            <option value="0">Reject</option>
                        </select>
                    )}
                </>
            ),
            center: true,
        },
    ];

    const withdrawColumns = [
        // Withdrawal columns
        {
            name: "ID",
            selector: (row) => row.withdrawal_id.slice(-12),
            sortable: true,
            center: true,
        },
        {
            name: "Amount (RM)",
            selector: (row) => row.amount,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Account",
            selector: (row) => row.bank_account_no,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Name",
            selector: (row) => row.bank_list_name,
            sortable: true,
            center: true,
        },
        {
            name: "Status",
            cell: (row) => {
                const statusMap = {
                    "2": { text: "Pending Verification", bgColor: "orange" },
                    "1": { text: "Success", bgColor: "green" },
                    "0": { text: "Cancelled", bgColor: "red" },
                };
                const status = statusMap[row.status] || { text: "Unknown", bgColor: "gray" };

                return (
                    <div
                        style={{
                            backgroundColor: status.bgColor,
                            color: "white",
                            fontWeight: "bold",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            textAlign: "center",
                            display: "inline-block",
                        }}
                    >
                        {status.text}
                    </div>
                );
            },
            sortable: true,
            center: true,
        },
        {
            name: "Created Time",
            selector: (row) => moment.unix(row.created_time).format("DD/MM/YYYY HH:mmA"),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    {row.status === 2 && (
                        <select
                            className="form-control form-control-sm"
                            value=""
                            onChange={(e) => handleOpenModal(row, e.target.value)}
                        >
                            <option value="">Select Action</option>
                            <option value="1">Mark as Completed</option>
                            <option value="0">Reject</option>
                        </select>
                    )}
                </>
            ),
            center: true,
        },
    ];

    const fetchTransactions = async () => {
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            token: localStorage.getItem("token"),
        };
        const payload = {
            start_date: format_start_date,
            end_date: format_end_date,
            search_type: searchType, // 0: All, 1: Phone Number, 2: Name
            query: query,
        }
        try {
            API.get('/internal/totalStudentRegistration', { headers }).then(response => {
                if (response.data.response_code == 1000) {
                    setState(response.data.data)
                    //history.push(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            API.get('/internal/totalSpendingToday', { headers }).then(response => {
                if (response.data.response_code == 1000) {
                    setSpending(response.data.data)
                    //history.push(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            API.get('/internal/totalWalletBalance', { headers }).then(response => {
                if (response.data.response_code == 1000) {
                    setWalletBalance(response.data.data.totalParentsBalance)
                    //history.push(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            API.get('/internal/pendingTopUp', { headers }).then(response => {
                if (response.data.response_code == 1000) {
                    setPendingTopUp(response.data.data.total)
                    //history.push(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            API.get('/internal/totalUserRegistered', { headers }).then(response => {
                
                if (response.data.response_code == 1000) {
                  
                    setUserRegistered(response.data.data.totalUser)
                    //history.push(`/dashboard`);
                } else if (response.data.response_code == 2000) {
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                } else {
                    console.log(response)
                    toast.error(`${response.data.message}`, { autoClose: 5000 })
                }
            })
            // const topupResponse = await API.post("/internal/topupTxn", payload,{ headers });
           
            // if (topupResponse.data.response_code === 1000) {
            //     setData(topupResponse.data.data);
            // } else {
            //     toast.error(topupResponse.data.message, { autoClose: 5000 });
            // }
            const withdrawResponse = await API.get("/internal/withdrawTxn", { headers });
            if (withdrawResponse.data.response_code === 1000) {
                setWithdrawTxn(withdrawResponse.data.data);
            } else {
                toast.error(withdrawResponse.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error("Failed to fetch transactions.", { autoClose: 5000 });
        }
    };

    useEffect(() => {
        fetchTransactions();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-100">
                        <div className="row">
                        <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <>
                                            <span>
                                                &nbsp;
                                                <CountUp className="counter" end={userRegistered} separator="," />
                                            </span>
                                            &nbsp;
                                        </>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Registered User till date
                                        </span>
                                    }
                                />
                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <>
                                            <span>
                                                &nbsp;
                                                <CountUp className="counter" end={state.totalStudent} separator="," />
                                            </span>
                                            &nbsp;
                                        </>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Registered Students till date
                                        </span>
                                    }
                                />
                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <div className=''>
                                            RM <CountUp className="counter" end={spending.totalSpend} separator="," />

                                        </div>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Spending for Today

                                        </span>
                                    }
                                />

                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <div className=''>
                                            RM <CountUp className="counter" end={spending.totalFee} separator="," />

                                        </div>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Earnings for Today

                                        </span>
                                    }
                                />

                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <div className=''>
                                            RM <CountUp className="counter" end={pendingTopUp} separator="," />

                                        </div>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Pending Topup (Verifications)
                                        </span>
                                    }
                                />
                            </div>
                            <div className="col-xl-4 col-lg-12">
                                <DashboardCard
                                    mainComponent={
                                        <div className=''>
                                            RM <CountUp className="counter" end={walletBalance} separator="," />

                                        </div>}
                                    subComponent={
                                        <span className='text-red font-weight-bold'>
                                            Total Wallet Balance

                                        </span>
                                    }
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                        <div className="row">
                            <div className="col-xl-12 xl-100">
                                <div className="card rounded-25">
                                    <div className="card-body p-0">
                                        <div className="col-12 card-header rounded-25 server-header space-evenly pl-5 pr-5">
                                            <div className='col-8 form-group form-row justify-content-start '>

                                                <div className="form-group form-row m-0 p-2">
                                                    <label className=" col-form-label text-end">Start Date:</label>
                                                    <div className="">
                                                        <div className="input-group">
                                                            <DatePicker
                                                                className="form-control digits"
                                                                selected={startDate}
                                                                onChange={handleStartDate} 
                                                                dateFormat="dd-MM-yyyy"
                                                                maxDate={new Date()}
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group form-row m-0 p-2'>
                                                    <label className="col-form-label text-end">End Date:</label>
                                                    <div className="">
                                                        <div className="input-group">
                                                            <DatePicker
                                                                className="form-control digits"
                                                                selected={endDate}
                                                                onChange={handleEndDate}
                                                                dateFormat="dd-MM-yyyy"
                                                                maxDate={new Date()}
                                                                 />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex">
                                                    <Button color="primary" className="" name="basic" onClick={() => onClickDownload()}>Download CSV</Button>
                                                </div>
                                                </div>
                                               
                                            </div>
                                         
                                            <div className='col-4 form-group form-row justify-content-end m-0'>
                                                <div className='col-xxl-4 col-xl-12 col-12'>
                                                    <Dropdown className="" isOpen={dropdownOpen} toggle={toggle}>
                                                        <DropdownToggle className="nav-link" tag="a" href="#" color="#005AFF" style={{color:'#005AFF'}} caret>
                                                            {searchType == "2" || searchType == "0" ? "Name" : "Phone Number"}
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ color: '#005AFF' }}>
                                                            <DropdownItem onClick={() => setSearchType("2")}>Name</DropdownItem>
                                                            {/* <DropdownItem onClick={() => setSearchType("1")}>Phone Number</DropdownItem> */}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                {/* <div className='col-xxl-8 col-12 d-flex'>
                                                    <div className="job-filter">
                                                        <div className="faq-form">
                                                            <input className="form-control" type="text" placeholder="Search.." value={query} onChange={(e) => setQuery(e.target.value)} />
                                                            <Search className="search-icon" />
                                                        </div>
                                                    </div>
                                                    &nbsp;&nbsp;
                                                    <Button color="primary" className="" name="basic" onClick={() => debouncedSearch()}>Search</Button>
                                                    
                                                    
                                                </div>              */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="card-body">
                            <div className="col-12 d-flex justify-content-between align-items-center">
                                <h5 className="mb-3">Top-up Transactions</h5>
                                <div className="font-weight-bold">Total Transactions: {data.length}</div>
                            </div>
                            {data.length == 0 ? (
                                    <div className="text-center font-weight-bold">
                                        No transactions found.
                                    </div>
                                ) : <DataTable 
                                    columns={columns}
                                     data={data} 
                                     striped={true} 
                                     persistTableHead />}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body">
                                <h5>Withdraw Transactions</h5>
                                <DataTable
                                    columns={withdrawColumns}
                                    data={withdrawTxn}
                                    striped={true}
                                    persistTableHead
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && (
                    <div
                        className="modal show"
                        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        {modalData.newStatus === "0"
                                            ? "Reject Transaction"
                                            : "Mark as Completed"}
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleCloseModal}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label className="form-label">Remarks</label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            placeholder="Enter remarks"
                                            value={remarks}
                                            onChange={(e) => setRemarks(e.target.value)}
                                        ></textarea>
                                    </div>
                                    {modalData.newStatus === "1" && (
                                        <div className="mb-3">
                                            <label className="form-label">Amount (RM)</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={handleCloseModal}
                                    >
                                        Close
                                    </button>
                                    <button className="btn btn-primary" onClick={handleModalSubmit}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default Homepage;

const DashboardCard = (props) => {
    //null,"dark"
    const [cardClassName, setCardClassName] = useState(props.theme || "");
    return (
        <div className={"card rounded-25 text-center " + cardClassName}>
            <div className="card-body">
                <div className="">
                    <div className="mb-0 mainComponent">
                        {props.mainComponent}
                    </div>
                    <div className='subComponent pt-3'>
                        {props.subComponent}
                    </div>
                </div>
            </div>
        </div>
    )
}