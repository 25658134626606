import React, { useState, Fragment, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import API from '../../services/API';

const MerchantPage = () => {
    const [txn, setTxn] = useState([]);
    const [merchant, setMerchant] = useState([]);
    const [showModal, setShowModal] = useState(false);
    
    const [currentMerchantId, setCurrentMerchantId] = useState(null);
    const [actionType, setActionType] = useState("");
    const [remarks, setRemarks] = useState("");

    const handleAction = (action, merchantDetails) => {
        if (action === "complete" || action === "reject") {
            const bankAccountLast4 = "**** "+ merchantDetails?.bank_account_no?.slice(-4) || "****";
            setCurrentMerchantId(merchantDetails);
            setActionType(action);
    
            // Set default remarks based on action
            const defaultRemark =
                action === "complete"
                    ? `Transaction marked as completed and will be transferred to your bank account ending in ${bankAccountLast4}.`
                    : "Transaction rejected due to policy reasons.";
            setRemarks(defaultRemark);
    
            setShowModal(true);
        } else if (action === "view") {
            toast.info(`Viewing details for merchant ${merchantDetails}`, { autoClose: 3000 });
        }
    };
    
    const handleSettleTxnFromMerchant = async(merchantID)=>{
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        const payload = { 
            merchant_uuid: merchantID,
        };
        try {
            const response = await API.post('/transaction/settle', payload, { headers });
            if (response.data.response_code === 1000) {
                try {
                    const response = await API.post('/merchant/startSettlement', payload, { headers });
                    if (response.data.response_code === 1000) {
                        toast.success(response.data.message,
                            { autoClose: 5000 }
                        );
                        fetchTransactions(); // Refresh the transaction data
                    } else {
                        toast.error(response.data.message, { autoClose: 5000 });
                    }
                } catch (error) {
                    toast.error("Failed to complete the action. Please try again.", { autoClose: 5000 });
                }
                fetchTransactions(); // Refresh the transaction data
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            toast.error("Failed to complete the action. Please try again.", { autoClose: 5000 });
        }
    }
    const handleSettlementTxnSetup = async () => {
        if (!remarks.trim()) {
            toast.error("Remarks cannot be empty.", { autoClose: 3000 });
            return;
        }

        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        const status = actionType === "complete" ? 2 : 0; // Set status based on action
        const payload = { 
            merchant_uuid: currentMerchantId.merchant_uuid,
            txn_uuid:currentMerchantId.uuid,
            status,
            remarks,
        };

        try {
            const response = await API.post('/merchant/updateSettlement', payload, { headers });
            if (response.data.response_code === 1000) {
                toast.success(
                    actionType === "complete" 
                        ? "Settlement Completed!" 
                        : "Transaction Cancelled!",
                    { autoClose: 5000 }
                );
                setShowModal(false);
                setRemarks("");
                fetchTransactions(); // Refresh the transaction data
            } else {
                setShowModal(false);
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.log(error)
            toast.error("Failed to complete the action. Please try again.", { autoClose: 5000 });
        }
    };

    const [columns] = useState([
        {
            name: "ID",
            selector: (row) => row.merchant_uuid,
            sortable: true,
            center: true,
        },
        {
            name: "Merchant Name",
            selector: (row) => row.merchant_name,
            sortable: true,
            center: true,
        },
        {
            name: "Total Amount (RM)",
            selector: (row) => parseFloat(row.total_amount).toFixed(2),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            cell: row => (
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleSettleTxnFromMerchant(row.merchant_uuid)}
                >
                    Completed
                </button>
            ),
            center: true,
        },
    ]);

    const [secondColumn] = useState([
        {
            name: "ID",
            selector: (row) => row.merchant_uuid,
            sortable: true,
            center: true,
        },
        {
            name: "Merchant Name",
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Name",
            selector: (row) => row.bank_account_name,
            sortable: true,
            center: true,
        },
        {
            name: "Bank Account No",
            selector: (row) => row.bank_account_no,
            sortable: true,
            center: true,
        },
        {
            name: "Total Amount (RM)",
            selector: (row) => parseFloat(row.amount).toFixed(2),
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            cell: row => (row.status=='1'?
                <select
                    className="form-select form-select-sm"
                    onChange={(e) => handleAction(e.target.value, row)}
                >
                    <option value="">Select Action</option>
                    <option value="complete">Mark as Completed</option>
                    <option value="reject">Reject</option>
                </select>:row.status=='0'?'Rejected':row.status=='2'?'Completed':null
            ),
            center: true,
        },
    ]);

    const fetchTransactions = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        try {
            const response = await API.get('/internal/settlementTxn', { headers });
            if (response.data.response_code === 1000) {
                setTxn(response.data.data);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch transactions.", { autoClose: 5000 });
        }
    };

    useEffect(() => {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
        };
        API.get('/internal/txnPendingSettle', { headers }).then(response => {
            if (response.data.response_code === 1000) {
                setMerchant(response.data.data);
            } else {
                toast.error(response.data.message, { autoClose: 5000 });
            }
        });
        fetchTransactions();
    }, []);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25">
                                <h5>Merchants</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={columns}
                                        data={merchant}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12 xl-50">
                        <div className="card rounded-25">
                            <div className="card-header rounded-25">
                                <h5>Settlements</h5>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <DataTable
                                        columns={secondColumn}
                                        data={txn}
                                        striped={true}
                                        center={true}
                                        persistTableHead
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Remarks</h5>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder="Enter remarks"
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                <button className="btn btn-primary" onClick={()=>handleSettlementTxnSetup()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default MerchantPage;
