import React, { useState } from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import configDB from '../data/customizer/config'
import PrivateRoute from './private-route';
import AppLayout from '../components/app';
import LoginPage from '../pages/login';
import FirstTimeLoginPage from '../pages/firstTimeLogin';
import ForgetPwd from '../pages/forgetPwd';
import ComingSoon from '../pages/comingsoon';
import ComingSoonImg from '../pages/comingsoonImg';
import Maintenance from '../pages/maintenance';
import Error400 from '../pages/errors/error400';
import Error401 from '../pages/errors/error401';
import Error403 from '../pages/errors/error403';
import Error404 from '../pages/errors/error404';
import Error500 from '../pages/errors/error500';
import Error503 from '../pages/errors/error503';
import MerchantPage from '../components/dashboard/Merchant';
import { routes } from './layouts-routes';
import Callback from '../auth/callback';
import API from '../services/API';
import { useNavigate } from 'react-router-dom';


const MainRoutes = () => {
  const jwt_token = localStorage.getItem('token');
  useEffect(() => {
    const abortController = new AbortController();
    //fetch('/users', requestOptions).then(handleResponse)
    const color = localStorage.getItem('color')
    const layout = localStorage.getItem('layout_version') || configDB.data.color.layout_version
    document.body.classList.add(layout);
    document.getElementById("color").setAttribute("href", `/assets/css/${color}.css`);
    //getToken();
    return function cleanup() {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <Routes>
        <Route exact path={`/`} element={<LoginPage />} />
        <Route path={`/first`} element={<FirstTimeLoginPage />} />
        <Route exact path='/' element={<PrivateRoute />} >
          {routes.map(({ path, Component }, i) => (
            <Route element={<AppLayout />} key={i}>
              {jwt_token && <Route exact
                path={`/`}
                element={<Navigate to={`/dashboard`} />}
              />}
              <Route exact path={path} element={Component} />
              <Route path={`/merchant`} element={<MerchantPage/>}/>
            </Route>
          ))}
        </Route>
       
        <Route path={`/forgot-password`} element={<ForgetPwd />} />
        <Route path={`/pages/comingsoon`} element={<ComingSoon />} />
        <Route path={`/pages/comingsoonImg`} element={<ComingSoonImg />} />
        <Route path={`/pages/maintenance`} element={<Maintenance />} />
        <Route path={`/pages/errors/error400`} element={<Error400 />} />
        <Route path={`/pages/errors/error401`} element={<Error401 />} />
        <Route path={`/pages/errors/error403`} element={<Error403 />} />
        <Route path={`/pages/errors/error404`} element={<Error404 />} />
        <Route path={`/pages/errors/error500`} element={<Error500 />} />
        <Route path={`/pages/errors/error503`} element={<Error503 />} />
      </Routes>
    </>
  );


};

export default MainRoutes;